/* eslint-disable react/no-unstable-nested-components */
import type { CellProps, Column } from 'react-table';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Badge,
  Button,
  HardwareIcon,
  HStack,
  Pane,
  PaneContent,
  space,
} from '@meterup/atto';
import { Priority, useCommand, useRegisterCommands } from '@meterup/command';
import {
  checkDefinedOrThrow,
  DisabledBadge,
  expectDefinedOrThrow,
  isDefined,
  NeutralBadge,
  OnlineOfflineDraftBadge,
  ResourceNotFoundError,
  Tooltip,
} from '@meterup/common';
import { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import type { CSVRowFormatter } from '../../../components/AutoTable/AutoTable';
import { fetchControllerJSON } from '../../../api/controllers_api';
import { fetchAccessPointsJSON, fetchFloorPlan } from '../../../api/devices_api';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { APCSVUploadDialog } from '../../../components/Device/APCSVUploadDialog';
import { UploadFloorPlanDialog } from '../../../components/Device/UploadFloorPlanDialog';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';
import { NosFeature, useNosFeatureEnabled } from '../../../utils/nosfeature';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/devices',
});

function RadioNumbers({ value }: { value: api.AccessPointRadioConfig }) {
  return (
    <HStack spacing={space(4)}>
      <Tooltip content="Channel">
        <Badge size="small">{value.channel}</Badge>
      </Tooltip>
      <Tooltip content="Channel Width">
        <Badge size="small">{value.channel_width_MHz}</Badge>
      </Tooltip>
      <Tooltip content="Power">
        <Badge size="small">{value.tx_power_dBm}</Badge>
      </Tooltip>{' '}
    </HStack>
  );
}

const columns: Column<api.AccessPointDeviceResponse>[] = [
  {
    Header: 'Location',
    accessor: (row) => row.location,
    Cell: (props: CellProps<api.AccessPointDeviceResponse, string>) => (
      <HStack spacing={space(8)}>
        <HardwareIcon icon="access-point" size={16} variant="simple" />
        {props.value}
      </HStack>
    ),
  },
  {
    Header: 'Name',
    accessor: (row) => row.name,
  },
  {
    Header: '5G band',
    accessor: (row) => {
      const radio = row.radios.find((r) => r.band === api.RadioBand.RB_5G && r.enabled) ?? null;
      return radio
        ? [
            radio.channel.toString(),
            radio.channel_width_MHz.toString(),
            radio.tx_power_dBm.toString(),
          ].join(', ')
        : null;
    },
    Cell: (props: CellProps<api.AccessPointDeviceResponse>) => {
      const value =
        props.row.original.radios.find(
          (radio) => radio.band === api.RadioBand.RB_5G && radio.enabled,
        ) ?? null;
      return value ? <RadioNumbers value={value} /> : <DisabledBadge />;
    },
  },
  {
    Header: '2.4G band',
    accessor: (row) => {
      const radio = row.radios.find((r) => r.band === api.RadioBand.RB_2G && r.enabled) ?? null;
      return radio
        ? [
            radio.channel.toString(),
            radio.channel_width_MHz.toString(),
            radio.tx_power_dBm.toString(),
          ].join(', ')
        : null;
    },
    Cell: (props: CellProps<api.AccessPointDeviceResponse>) => {
      const value =
        props.row.original.radios.find(
          (radio) => radio.band === api.RadioBand.RB_2G && radio.enabled,
        ) ?? null;
      return value ? <RadioNumbers value={value} /> : <DisabledBadge />;
    },
  },
  {
    Header: 'Version',
    accessor: (row) => {
      /* if configured_build_name is valid, compare that to the reported build name. If equal then display version, otherwise display build name */
      if (row.configured_build_name === '' || row.configured_build_name === row.build_name) {
        return row.firmware_version;
      }
      return row.build_name;
    },
    Cell: (props: CellProps<api.AccessPointDeviceResponse>) => (
      <NeutralBadge ends="card">{props.value}</NeutralBadge>
    ),
  },
  {
    Header: 'Clients',
    accessor: (row) => row.connected_clients,
  },
  {
    Header: 'Status',
    accessor: (row) => row.connected_status,
    Cell: (props: CellProps<api.AccessPointDeviceResponse>) => (
      <OnlineOfflineDraftBadge value={props.value} />
    ),
  },
];

const csvRowFormatter: CSVRowFormatter<api.AccessPointDeviceResponse> = (
  row: api.AccessPointDeviceResponse,
) =>
  row.radios.map((radio, index) => ({
    name: row.name,
    location: row.location,
    index,
    channel: radio.channel,
    power: radio.tx_power_dBm,
    channel_width: radio.channel_width_MHz,
    disabled: !radio.enabled,
    serial_number: row.serial_number,
  }));

export default function ControllerDevicesList() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDevicesList),
  );

  const network = useQuery(
    ['controller', controllerName, 'json'],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(network, new ResourceNotFoundError('Controller not found'));

  const accessPoints =
    useQuery(
      ['controller', controllerName, 'accessPoints'],
      () => fetchAccessPointsJSON(controllerName),
      {
        suspense: true,
      },
    ).data ?? [];

  const floorPlan = useQuery(['controller', controllerName, 'floor-plan'], async () =>
    fetchFloorPlan(controllerName),
  ).data;

  useDocumentTitle('Devices', controllerName, network);

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.DeviceDetail);

  const floorPlanButton = isDefined(floorPlan) ? (
    <Button
      arrangement="leading-icon"
      icon="floor-plan"
      variant="secondary"
      as={Link}
      to={makeDrawerLink(paths.drawers.FloorPlanDetail, { controllerName })}
    >
      View floor plan
    </Button>
  ) : (
    <UploadFloorPlanDialog
      controllerName={controllerName}
      trigger={
        <Button icon="floor-plan" variant="secondary">
          Upload floor plan
        </Button>
      }
    />
  );

  const [inContextAccessPoint, setInContextAccessPoint] =
    useState<api.AccessPointDeviceResponse | null>(null);

  const navigate = useNavigate();
  const { state } = useCommand();
  useRegisterCommands(
    inContextAccessPoint
      ? [
          state.nodeFactory.action({
            id: 'edit-access-point-details',
            label: 'Edit details',
            display: 'Edit details',
            priority: Priority.High,
            group: inContextAccessPoint.name,
            onSelect() {
              navigate(
                makeDrawerLink(paths.drawers.DeviceEditLocation, {
                  controllerName,
                  id: inContextAccessPoint.name ?? '',
                }),
              );
            },
          }),
          state.nodeFactory.action({
            id: 'view-clients',
            label: 'View Clients',
            display: 'View Clients',
            priority: Priority.High,
            group: inContextAccessPoint.name,
            onSelect() {
              navigate(
                makeLink(paths.pages.ControllerDeviceClients, {
                  controllerName,
                  deviceName: inContextAccessPoint.name ?? '',
                }),
              );
            },
          }),
        ]
      : [],
    [inContextAccessPoint],
  );

  const isWOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.WOS2);

  return (
    <PaneContent>
      {isWOS2Enabled && (
        <Alert
          icon="attention"
          variant="attention"
          heading="This network uses WOS Config 2.0"
          copy="AP details in this page are invalid. Please view dashboard for AP information."
          relation="stacked"
          shoulderButtons={
            network ? (
              <Button
                variant="secondary"
                as="a"
                target="_blank"
                href={`${import.meta.env.DASHBOARD_URL}/org/${
                  network.company_slug
                }/network/${network.network_uuid}`}
              >
                View dashboard
              </Button>
            ) : undefined
          }
        />
      )}
      <Pane>
        <AutoTable
          additionalControls={
            <>
              {floorPlanButton}
              <APCSVUploadDialog
                controllerName={controllerName}
                trigger={
                  <Button arrangement="leading-icon" icon="upload" variant="secondary">
                    Import
                  </Button>
                }
              />
            </>
          }
          isRowSelected={(row) => row.name === drawerParams?.id}
          linkProps={(row) => ({
            to: makeDrawerLink(paths.drawers.DeviceDetail, {
              controllerName,
              id: row.name ?? '',
            }),
            onPointerEnter() {
              setInContextAccessPoint(row);
            },
          })}
          columns={columns}
          data={accessPoints}
          csvFileName={`${controllerName}-devices.csv`}
          csvRowFormatter={csvRowFormatter}
        />
      </Pane>
    </PaneContent>
  );
}
